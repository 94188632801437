import { FC, useEffect, useState, useMemo, useCallback } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { onMessage, MessagePayload } from "firebase/messaging";
import { useAuth } from "../../../../app/modules/auth";
import apiProvider from "../../../../utils/apiProvider";
import { messaging } from "../../../../utils/firebase";

interface Notification {
  id: number;
  title: string;
  body: string;
  type: "promotion" | "transaction" | "negotiation" | "withdraw" | string;
  value: string;
  is_read: boolean;
  date: string;
}

interface NotificationCount {
  all: number;
  promotion: number;
  transaction: number;
  negotiation: number;
}

const HeaderNotificationsMenu: FC = () => {
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 99999999,
    total: 0,
    total_pages: 0,
  });
  const { currentUser } = useAuth();
  const [notificationList, setNotificationList] = useState<Notification[]>([]);

  const [zeroData, setZeroData] = useState("");

  const handleNotification = useCallback(async () => {
    setLoading(true);
    const response = await apiProvider.GetNotificationList(
      currentUser?.token,
      meta.page,
      meta.per_page
    );

    if (response.status === 200 && response.data.data.length > 0) {
      setMeta((prevMeta) => ({
        ...prevMeta,
        total: response.data.data.total,
        total_pages: response.data.data.total_pages,
      }));
      setNotificationList((prevList) => [
        ...prevList,
        ...response.data.data.map((notification: Notification) => ({
          ...notification,
          date: new Date(notification.date)
            .toLocaleString("id-ID", {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
            .replace(" pukul", ""),
        })),
      ]);
    } else {
      setZeroData(response.data.message);
      setNotificationList([]);
      setMeta((prevMeta) => ({
        ...prevMeta,
        total: 0,
        total_pages: 0,
      }));
    }
    setLoading(false);
  }, [currentUser?.token, meta.page, meta.per_page]);

  useEffect(() => {
    handleNotification();

    const unsubscribe = onMessage(messaging, (payload: MessagePayload) => {
      console.log("Message received. ", payload);
      const notification = payload.notification;
      if (notification) {
        const { title, body } = notification;
        const type = payload.data?.type || "";
        const value = payload.data?.value || "";
        const date = payload.data?.date || new Date().toISOString();

        if (title && body) {
          setNotificationList((prevList) => [
            ...prevList,
            {
              id: new Date().getTime(),
              title,
              body,
              type,
              value,
              is_read: false,
              date,
            },
          ]);

          window.alert(`New Notification:\nTitle: ${title}\nBody: ${body}`);
        }
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [handleNotification]);

  useEffect(() => {
    if (currentUser?.token) {
      handleNotification();
    }
  }, [currentUser?.token, meta.page, meta.per_page, handleNotification]);

  const readNotification = useCallback(
    async (id: number) => {
      setNotificationList((prevList) =>
        prevList.map((alert) =>
          alert.id === id ? { ...alert, is_read: true } : alert
        )
      );

      try {
        await apiProvider.ReadNotification(currentUser?.token, id);
      } catch (error) {
        console.error("Gagal menandai notifikasi sebagai telah dibaca:", error);
      }
    },
    [currentUser?.token]
  );

  const readAllNotification = useCallback(async () => {
    setNotificationList((prevList) =>
      prevList.map((alert) => ({ ...alert, is_read: true }))
    );

    try {
      await apiProvider.ReadAllNotification(currentUser?.token);
    } catch (error) {
      console.error(
        "Gagal menandai semua notifikasi sebagai telah dibaca:",
        error
      );
    }
  }, [currentUser?.token]);

  const notificationItems = useMemo(
    () =>
      notificationList.length > 0 ? (
        notificationList.map((alert, index) => (
          <div
            key={alert.id - index}
            className="d-flex flex-stack py-4 position-relative"
            onClick={() => readNotification(alert.id)}
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex align-items-center">
              <div className="symbol symbol-35px me-4 position-relative">
                {!alert.is_read && (
                  <div className="position-absolute top-0 end-0 p-1">
                    <span className="text-danger fs-6">*</span>
                  </div>
                )}
                <span className="symbol-label bg-light-primary">
                  <i className="ki-duotone ki-notification-bing fs-2 text-primary">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                  </i>
                </span>
              </div>

              <div className="mb-0 me-2">
                <a
                  href="#"
                  className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                >
                  {alert.title}
                </a>
                <div className="text-gray-500 fs-7">{alert.body}</div>
              </div>
            </div>
            <span className="badge badge-light fs-8">{alert.date}</span>
          </div>
        ))
      ) : (
        <div className="text-center">{zeroData}</div>
      ),
    [notificationList, readNotification]
  );

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
      data-kt-menu="true"
    >
      <div
        className="d-flex flex-column bgi-no-repeat rounded-top"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/menu-header-bg.jpg"
          )}')`,
        }}
      >
        <div className="d-flex justify-content-between align-items-center px-9 mt-10 mb-6">
          <h3 className="text-white fw-bold">Pemberitahuan</h3>
          {/* <button
            className="btn btn-sm btn-light-primary p-2"
            onClick={readAllNotification}
          >
            Baca Semua
          </button> */}
        </div>
      </div>

      <div className="tab-content">
        <div className="tab-pane fade show active d-flex flex-column">
          <div className="flex-grow-1 scroll-y mh-325px my-5 px-8">
            {notificationItems}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
