import React, { useCallback, useEffect, useRef, useState } from "react";
import { KTCardBody, KTSVG } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { Pagination } from "../../global/Pagination";
import { MarketplaceSkeleton } from "../skeleton/MarketPlaceSkeleton";
import RupiahFormat from "../../global/Formatrupiah";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import { Empty, Spin } from "antd";
import SearchInput from "../../global/SearchInput";
import { AlertMessage } from "../../global/AlertMessage";

type ReportData = {
  id: number;
  code: string;
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  fee_transaction: number;
  fee_seller: number;
  fee_payment: number;
  fee_expedition: number;
  grand_total: number;
  income: number;
};

const ReportList: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [reportData, setReportData] = useState<ReportData[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  }); // Initialize with default values
  const [startIndex, setStartIndex] = useState(0);
  const flatpickrRef = useRef<any>(null);
  const [zeroData, setZeroData] = useState("");
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  useEffect(() => {
    if (currentUser?.token) {
      getReport();
    }
  }, [
    currentUser,
    meta.page,
    searchQuery,
    formattedStartDate,
    formattedEndDate,
    meta.per_page,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getReport = async () => {
    setLoading(true);
    const res = await API.GetReport(
      token,
      formattedStartDate,
      formattedEndDate,
      meta.page,
      searchQuery,
      meta.per_page
    );
    if (res.status === 200 && res.data.data.length > 0) {
      setReportData(res.data.data);
      setMetaData(res.data.meta);
    } else {
      setReportData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(res.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleDateChange = useCallback((selectedDates: Date[]) => {
    const [startDate, endDate] = selectedDates;
    const start = startDate ? startDate.toISOString().split("T")[0] : "";
    console.log(start, "start date");

    const end = endDate ? endDate.toISOString().split("T")[0] : "";
    setFormattedStartDate(start);
    setFormattedEndDate(end);
  }, []);

  const handleClearDate = () => {
    setFormattedStartDate("");
    setFormattedEndDate("");

    if (flatpickrRef.current !== null) {
      flatpickrRef.current.flatpickr.clear();
    }
    getReport();
  };

  const handleExport = async () => {
    setLoading(true);
    const res = await API.ExportReport(
      currentUser?.token,
      formattedStartDate,
      formattedEndDate
    );
    if (res.status === 200) {
      await getReport();
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `reports.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSuccessMessage("File berhasil diunduh");
      setShowAlert(true);
    } else {
      setShowAlertError(true);
      setErrorMessage("Gagal mengekspor laporan");
    }
    setLoading(false);
  };

  return (
    <div className="card mb-xl-10">
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className="card mb-xl-8">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Laporan Pendapatan</h3>
          </div>
        </div>
        <div className="separator separator"></div>
        <div className="card-header border-0 pt-6">
          <div className="card-toolbar d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <div className="input-group w-300px me-4">
                <Flatpickr
                  onChange={handleDateChange}
                  options={{
                    mode: "range",
                    locale: Indonesian,
                    altInput: true,
                    altFormat: "d F Y",
                  }}
                  className="form-control form-control-solid form-control-sm"
                  placeholder="Pilih rentang waktu"
                  ref={flatpickrRef}
                />
                <button
                  className="btn btn-icon btn-light btn-sm"
                  onClick={handleClearDate}
                >
                  <i className="fa fa-duotone bi-x fs-2">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </button>
              </div>
              <div
                className="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-sm btn-light-primary"
                  onClick={handleExport}
                  disabled={loading}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-2"
                  />
                  Ekspor Laporan
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center position-relative">
              <SearchInput placeholder="Search" onSearch={handleSearch} />
            </div>
          </div>
        </div>

        <KTCardBody className="py-4">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                  <th className="min-w-60px rounded-start">No</th>
                  <th>Kode Transaksi</th>
                  <th>Biaya Pengiriman</th>
                  <th>Biaya Tambahan</th>
                  <th>Biaya Transaksi</th>
                  <th>Biaya Penjual</th>
                  <th>Biaya Pembayaran</th>
                  <th>Biaya Ekspedisi</th>
                  <th>Subtotal</th>
                  <th>Grand Total</th>
                  <th className="pe-6 rounded-end">Pendapatan</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : reportData.length > 0 ? (
                  reportData.map((report, index) => (
                    <tr key={report.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {startIndex + index + 1}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <span className="text-dark fw-bold mb-1 fs-6">
                          {report.code}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-muted fw-bold mb-1 fs-6">
                          <RupiahFormat value={report.shipping_cost} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-muted fw-bold mb-1 fs-6">
                          <RupiahFormat value={report.extra_cost} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className="fw-bold mb-1 fs-6"
                          style={{ color: " #ffa500" }}
                        >
                          <RupiahFormat value={report.fee_transaction} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className="fw-bold mb-1 fs-6"
                          style={{ color: " #ffa500" }}
                        >
                          <RupiahFormat value={report.fee_seller} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className="fw-bold mb-1 fs-6"
                          style={{ color: " #ffa500" }}
                        >
                          <RupiahFormat value={report.fee_payment} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className="fw-bold mb-1 fs-6"
                          style={{ color: " #ffa500" }}
                        >
                          <RupiahFormat value={report.fee_expedition} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-muted fw-bold mb-1 fs-6">
                          <RupiahFormat value={report.subtotal} />
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-muted fw-bold mb-1 fs-6">
                          <RupiahFormat value={report.grand_total} />
                        </span>
                      </td>
                      <td className="text-center pe-6">
                        <span className="text-success fw-bold mb-1 fs-6">
                          <RupiahFormat value={report.income} />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </KTCardBody>
      </div>
    </div>
  );
};

export { ReportList };
